// src/Components/Footer.js
import React from 'react';
import '../Styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column">
          <h4>Company</h4>
          <ul>
            <li><a href="/about">About Us</a></li>
            <li><a href="/careers">Careers</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/terms">Terms of Service</a></li>
            <li><a href="/privacy">Privacy Policy</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Explore</h4>
          <ul>
            <li><a href="/destinations">Destinations</a></li>
            <li><a href="/tours">Tours</a></li>
            <li><a href="/flights">Flights</a></li>
            <li><a href="/deals">Exclusive Deals</a></li>
          </ul>
        </div>

        <div className="footer-column">
          <h4>Support</h4>
          <ul>
            <li><a href="/faq">FAQ</a></li>
            <li><a href="/support">Customer Support</a></li>
            <li><a href="/booking">Manage Booking</a></li>
            <li><a href="/insurance">Travel Insurance</a></li>
          </ul>
        </div>

        <div className="footer-column social-media">
          <h4>Follow Us</h4>
          <div className="social-icons">
            <a href="https://facebook.com" className="social-icon facebook">Facebook</a>
            <a href="https://twitter.com" className="social-icon twitter">Twitter</a>
            <a href="https://instagram.com" className="social-icon instagram">Instagram</a>
            <a href="https://linkedin.com" className="social-icon linkedin">LinkedIn</a>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} MMSTourism.com. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
