import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/RightPanel.css';

const RightPanel = () => {
  const [tripType, setTripType] = useState('oneWay');
  const [fromCity, setFromCity] = useState('');
  const [toCity, setToCity] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [departureDate, setDepartureDate] = useState('');

  const navigate = useNavigate();

  const handleTripTypeChange = (type) => {
    setTripType(type);
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    navigate('/flights', {
      // state: {
      //   tripType,
      //   fromCity,
      //   toCity,
      //   departureDate,
      //   returnDate,
      //   adults,
      //   children,
      //   infants,
      // },
    });
  };

  return (
    <div className="container booking-form">
      <h3 className="text-center">Book Your Flight</h3>
      <form onSubmit={handleSearchClick}>
        {/* Trip Type Radio Buttons in a Row */}
        <div className="form-group text-center">
          {/* <label className="trip-type-label">Select Trip Type:</label> */}
          <div className="d-flex justify-content-center">
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input custom-radio"
                name="tripType"
                id="oneWay"
                value="oneWay"
                checked={tripType === 'oneWay'}
                onChange={() => handleTripTypeChange('oneWay')}
              />
              <label className="form-check-label" htmlFor="oneWay">One Way</label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                className="form-check-input custom-radio"
                name="tripType"
                id="roundTrip"
                value="roundTrip"
                checked={tripType === 'roundTrip'}
                onChange={() => handleTripTypeChange('roundTrip')}
              />
              <label className="form-check-label" htmlFor="roundTrip">Round Trip</label>
            </div>
          </div>
        </div>

        {/* From, To, Dates, and Travelers */}
        <div className="form-col">
          <div className="form-group col-md-12">
            <label htmlFor="fromCity">From</label>
            <select className="form-control" id="fromCity" value={fromCity} onChange={(e) => setFromCity(e.target.value)}>
              <option value="">Select City/Airport</option>
              <option value="New York">New York</option>
              <option value="Los Angeles">Los Angeles</option>
              <option value="Chicago">Chicago</option>
            </select>
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="toCity">To</label>
            <select className="form-control" id="toCity" value={toCity} onChange={(e) => setToCity(e.target.value)}>
              <option value="">Select City/Airport</option>
              <option value="London">London</option>
              <option value="Tokyo">Tokyo</option>
              <option value="Paris">Paris</option>
            </select>
          </div>

          <div className="form-group col-md-12">
            <label htmlFor="departureDate">Departure Date</label>
            <input type="date" className="form-control" id="departureDate" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
          </div>
        </div>

        {tripType === 'roundTrip' && (
          <div className="form-group">
            <label htmlFor="returnDate">Return Date</label>
            <input type="date" className="form-control" id="returnDate" value={returnDate} onChange={(e) => setReturnDate(e.target.value)} />
          </div>
        )}

        <div className="form-row no-gap">
          <div className="form-group col-md-4">
            <label htmlFor="adults">Adults</label>
            <input type="number" className="form-control" id="adults" value={adults} onChange={(e) => setAdults(e.target.value)} min="1" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="children">Children</label>
            <input type="number" className="form-control" id="children" value={children} onChange={(e) => setChildren(e.target.value)} min="0" />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="infants">Infants</label>
            <input type="number" className="form-control" id="infants" value={infants} onChange={(e) => setInfants(e.target.value)} min="0" />
          </div>
        </div>

        <div className="text-center">
          <button type="submit" className="btn btn-color">Search Flights</button>
        </div>
      </form>
    </div>
  );
};

export default RightPanel;