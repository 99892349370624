import React from 'react';
import '../Styles/HotelPage.css';

const HotelPage= () => (
  <div className="search-container">
    <div className="search-box">
      <h2>Book Domestic and International Hotels</h2>
      <form>
        <div className="input-group">
          <label>Select Destination</label>
          <input type="text" placeholder="Enter your destination or property" />
        </div>
        <div className="date-group">
          <div className="input-group">
            <label>Check In</label>
            <input type="date" />
          </div>
          <div className="input-group">
            <label>Check Out</label>
            <input type="date" />
          </div>
        </div>
        <div className="input-group">
          <label>Rooms & Guests</label>
          <input type="number" placeholder="1 Room, 1 Guest" />
        </div>
        <div className='button-container'> 
        <button type="submit" className="search-button">Search Hotels</button>
        </div>
    
      </form>
    </div>
  </div>
);

export default HotelPage;
