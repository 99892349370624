import React from 'react';
import '../Styles/TripSidebar.css';

const TripSidebar = () => {
  return (
    <div className="sidebar">
      <h4>TRIP FOR DUBAI (10)</h4>
      <div className="filter">
        <h4>Flights</h4>
        <label>
          <input type="radio" name="flight" /> With Flight
        </label>
        <label>
          <input type="radio" name="flight" /> Without Flight
        </label>
      </div>

      <div className="filter">
        <h4>Holiday Type</h4>
        <label>
          <input type="checkbox" /> Customized Holidays
        </label>
        <label>
          <input type="checkbox" /> Fixed Departures
        </label>
      </div>

      <div className="filter">
        <h4>Budget</h4>
        <input type="range" min="27767" max="104153" />
        <p>₹ 27767 - ₹ 104153</p>
      </div>

      <div className="filter">
        <h4>Duration</h4>
        <input type="range" min="2" max="6" />
        <p>2N - 6N</p>
      </div>

      <div className="filter">
        <h4>Recommended For</h4>
        <label>
          <input type="checkbox" /> Cruise
        </label>
        <label>
          <input type="checkbox" /> Honeymoon
        </label>
        <label>
          <input type="checkbox" /> Shopping
        </label>
      </div>
    </div>
  );
};

export default TripSidebar;
