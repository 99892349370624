import React from 'react';
import '../Styles/FlightDetails.css'; // Importing the CSS for styling

const FlightDetails = () => {
  return (
    <div className="flight-container">
      <div className="flight-header">
        <div className="airline-info">
          {/*<img src="" alt="Air India Express" className="airline-logo" />*/}
          <span>Air India Express</span>
          <span>IX-1304</span>
        </div>
        <div className="flight-timing">
          <span className="flight-time">18:25</span>
          <span>Mumbai</span>
          <span>15hr 50min</span>
          <span>10:15</span>
          <span>New Delhi</span>
        </div>
        <div className="availability">
          <span>9 Left</span>
        </div>
      </div>

      <div className="flight-body">
        <div className="fare-type">
          <div className="fare-option">
            <input type="radio" id="xpressValue" name="fare" checked />
            <label htmlFor="xpressValue">XPRESS VALUE</label>
          </div>
          <div className="fare-option">
            <input type="radio" id="xpressFlex" name="fare" />
            <label htmlFor="xpressFlex">XPRESS FLEX</label>
          </div>
        </div>

        <table className="fare-details">
          <thead>
            <tr>
              <th>Fare Type</th>
              <th>XPRESS VALUE</th>
              <th>XPRESS FLEX</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Hand Bag</td>
              <td>07 KG</td>
              <td>7 Kgs</td>
            </tr>
            <tr>
              <td>Checked Bag</td>
              <td>15 Kgs</td>
              <td>15 Kgs</td>
            </tr>
            <tr>
              <td>Cancellation</td>
              <td><span className="icon">₹</span></td>
              <td><span className="icon">₹</span></td>
            </tr>
            <tr>
              <td>Date Change</td>
              <td><span className="icon">₹</span></td>
              <td><span className="icon check">✓</span></td>
            </tr>
            <tr>
              <td>Seat Selection</td>
              <td><span className="icon">₹</span></td>
              <td><span className="icon">₹</span></td>
            </tr>
            <tr>
              <td>Meal</td>
              <td><span className="icon">₹</span></td>
              <td><span className="icon">₹</span></td>
            </tr>
            <tr>
              <td>Amount</td>
              <td>Selected</td>
              <td>+ ₹831</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="flight-footer">
        <div className="total-amount">
          ₹ 6451
        </div>
        <button className="continue-btn">CONTINUE</button>
      </div>
    </div>
  );
};

export default FlightDetails;
