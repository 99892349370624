import React, { useState } from "react";
import '../../Styles/MainFlight/MainFlightSearch.css';
import FlightList from "./FlightList";
import FilterSection from "./FilterSection";
import DatePicker from "./DatePicker";

function MainFlightSearch() {
  const [flights, setFlights] = useState([
    {
      id: 1,
      airline: "SpiceJet",
      flightNumber: "SG - 710",
      departure: "23:10",
      duration: "2 Hr. 10 Min.",
      arrival: "01:20",
      price: "₹ 3999",
      discount: "Instant Discount ₹ 347",
      stops: 0,
      refundable: false
    },
    {
      id: 2,
      airline: "IndiGo",
      flightNumber: "6E - 2224",
      departure: "23:30",
      duration: "2 Hr. 05 Min.",
      arrival: "01:35",
      price: "₹ 4001",
      discount: "Instant Discount ₹ 150",
      stops: 0,
      refundable: false
    },
    {
        id: 1,
        airline: "SpiceJet",
        flightNumber: "SG - 710",
        departure: "23:10",
        duration: "2 Hr. 10 Min.",
        arrival: "01:20",
        price: "₹ 3999",
        discount: "Instant Discount ₹ 347",
        stops: 0,
        refundable: false
      },
      {
        id: 2,
        airline: "IndiGo",
        flightNumber: "6E - 2224",
        departure: "23:30",
        duration: "2 Hr. 05 Min.",
        arrival: "01:35",
        price: "₹ 4001",
        discount: "Instant Discount ₹ 150",
        stops: 0,
        refundable: false
      },
      {
        id: 1,
        airline: "SpiceJet",
        flightNumber: "SG - 710",
        departure: "23:10",
        duration: "2 Hr. 10 Min.",
        arrival: "01:20",
        price: "₹ 3999",
        discount: "Instant Discount ₹ 347",
        stops: 0,
        refundable: false
      },
      {
        id: 2,
        airline: "IndiGo",
        flightNumber: "6E - 2224",
        departure: "23:30",
        duration: "2 Hr. 05 Min.",
        arrival: "01:35",
        price: "₹ 4001",
        discount: "Instant Discount ₹ 150",
        stops: 0,
        refundable: false
      },
      {
        id: 1,
        airline: "SpiceJet",
        flightNumber: "SG - 710",
        departure: "23:10",
        duration: "2 Hr. 10 Min.",
        arrival: "01:20",
        price: "₹ 3999",
        discount: "Instant Discount ₹ 347",
        stops: 0,
        refundable: false
      },
      {
        id: 2,
        airline: "IndiGo",
        flightNumber: "6E - 2224",
        departure: "23:30",
        duration: "2 Hr. 05 Min.",
        arrival: "01:35",
        price: "₹ 4001",
        discount: "Instant Discount ₹ 150",
        stops: 0,
        refundable: false
      },
      {
        id: 1,
        airline: "SpiceJet",
        flightNumber: "SG - 710",
        departure: "23:10",
        duration: "2 Hr. 10 Min.",
        arrival: "01:20",
        price: "₹ 3999",
        discount: "Instant Discount ₹ 347",
        stops: 0,
        refundable: false
      },
      {
        id: 2,
        airline: "IndiGo",
        flightNumber: "6E - 2224",
        departure: "23:30",
        duration: "2 Hr. 05 Min.",
        arrival: "01:35",
        price: "₹ 4001",
        discount: "Instant Discount ₹ 150",
        stops: 0,
        refundable: false
      },
    // Add more flight objects similar to the above...
  ]);

  return (
    <div className="flight-search">
     <FilterSection />
     <div className="column-container">
          <DatePicker />
       <FlightList flights={flights} />
       </div>

    </div>
  );
}

export default MainFlightSearch;
