

// import React, { useState } from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import '../Styles/BookingForm.css';
// import ExclusiveDeals from './ExclusiveDeals';
// import TopDestination from './TopDestination';
// import Banner from './Banner';
// import Footer from './Footer';

// const BookingForm = () => {
//   const [tripType, setTripType] = useState('oneWay');
//   const [fromCity, setFromCity] = useState('');
//   const [toCity, setToCity] = useState('');
//   const [returnDate, setReturnDate] = useState('');
//   const [adults, setAdults] = useState(1);
//   const [children, setChildren] = useState(0);
//   const [infants, setInfants] = useState(0);
//   const [departureDate, setDepartureDate] = useState('');

//   const handleTripTypeChange = (type) => {
//     setTripType(type);
//   };

//   const handleSearch = (e) => {
//     e.preventDefault();
//     console.log('Search initiated');
//   };

//   const destinations = [
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580576/honeymoon-in-maldives-1-700x466_wedyd2.jpg',
//       name: 'Maldives',
//       description: 'Experience the beauty of pristine beaches and clear waters.',
//       price: 1500,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580637/best-places-to-live-in-paris-1_g2k4fs.jpg',
//       name: 'Paris',
//       description: 'Explore the romantic city and its stunning architecture.',
//       price: 1800,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580720/1eee_rcfgqp.jpg',
//       name: 'New Zealand',
//       description: 'Discover the adventure capital of the world.',
//       price: 3000,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580796/chureito-pagoda-and-mt-fuji-5c2fd0d546e0fb00015d4ed0_jmvtzu.jpg',
//       name: 'Japan',
//       description: 'Immerse yourself in rich culture and modern wonders.',
//       price: 2200,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580883/1-1ee_dnyhyr.jpg',
//       name: 'Dubai',
//       description: 'Immerse yourself in rich culture and modern wonders.',
//       price: 3200,
//     },
    
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581874/rajasthan_viultt.jpg',
//       name: 'Rajasthan',
//       description: 'Immerse yourself in rich culture and modern wonders.',
//       price: 2100,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581742/kerla_pdjlnw.jpg',
//       name: 'Kerla',
//       description: 'Immerse yourself in rich culture and modern wonders.',
//       price: 2700,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581803/goa_alcfft.jpg',
//       name: 'Goa',
//       description: 'Experience the beauty of pristine beaches and clear waters.',
//       price: 1900,
//     },
  
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581976/kashmir_gj56lt.jpg',
//       name: 'Kashmir',
//       description: 'Explore the romantic city and its stunning architecture.',
//       price: 5100,
//     },
//     {
//       image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582170/himachalPredesh_ndihnk.jpg',
//       name: 'Himachal Pradesh',
//       description: 'Discover the adventure capital of the world.',
//       price: 4000,
//     },
//   ];

//   return (
//     <div className='no-gap' >
//       <div className='app-container no-gap'>
//         <div className="container booking-form ">
//           <h3 className="text-center">Book Your Flight</h3>
//           <form onSubmit={handleSearch}>
//             {/* Trip type buttons */}
//             <div className="form-group text-center">
//               <div className="btn-group ">
//                 <button type="button" className={`btn btn-primary ${tripType === 'oneWay' ? 'active' : ''}`} onClick={() => handleTripTypeChange('oneWay')}>One Way</button>
//                 <button type="button" className={`btn btn-primary ${tripType === 'roundTrip' ? 'active' : ''}`} onClick={() => handleTripTypeChange('roundTrip')}>Round Trip</button>
//                 <button type="button" className={`btn btn-primary ${tripType === 'multiCity' ? 'active' : ''}`} onClick={() => handleTripTypeChange('multiCity')}>Multi-City</button>
//               </div>
//             </div>

//             {/* Form inputs */}
//             <div className="form-row ">
//               <div className="form-group col-md-4">
//                 <label htmlFor="fromCity">From</label>
//                 <select className="form-control" id="fromCity" value={fromCity} onChange={(e) => setFromCity(e.target.value)}>
//                   <option value="">Select City/Airport</option>
//                   <option value="New York">New York</option>
//                   <option value="Los Angeles">Los Angeles</option>
//                   <option value="Chicago">Chicago</option>
//                 </select>
//               </div>

//               <div className="form-group col-md-4">
//                 <label htmlFor="toCity">To</label>
//                 <select className="form-control" id="toCity" value={toCity} onChange={(e) => setToCity(e.target.value)}>
//                   <option value="">Select City/Airport</option>
//                   <option value="London">London</option>
//                   <option value="Tokyo">Tokyo</option>
//                   <option value="Paris">Paris</option>
//                 </select>
//               </div>

//               <div className="form-group col-md-4">
//                 <label htmlFor="departureDate">Departure Date</label>
//                 <input type="date" className="form-control" id="departureDate" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
//               </div>
//             </div>

//             {tripType !== 'oneWay' && (
//               <div className="form-group">
//                 <label htmlFor="returnDate">Return Date</label>
//                 <input type="date" className="form-control" id="returnDate" value={returnDate} onChange={(e) => setReturnDate(e.target.value)} />
//               </div>
//             )}

//             <div className="form-row no-gap">
//               <div className="form-group col-md-4">
//                 <label htmlFor="adults">Adults</label>
//                 <input type="number" className="form-control" id="adults" value={adults} onChange={(e) => setAdults(e.target.value)} min="1" />
//               </div>
//               <div className="form-group col-md-4">
//                 <label htmlFor="children">Children</label>
//                 <input type="number" className="form-control" id="children" value={children} onChange={(e) => setChildren(e.target.value)} min="0" />
//               </div>
//               <div className="form-group col-md-4">
//                 <label htmlFor="infants">Infants</label>
//                 <input type="number" className="form-control" id="infants" value={infants} onChange={(e) => setInfants(e.target.value)} min="0" />
//               </div>
//             </div>

//             <div className="text-center">
//               <button type="submit" className="btn btn-color">Search Flights</button>
//             </div>
//           </form>
//         </div>
//       </div>
//       <div>
//         <ExclusiveDeals />
//       </div>

//       <div>
//         <TopDestination destinations={destinations} />
//       </div>
//       <div>
//         <Banner />
//         {/* Other components can go here */}
//       </div>
//       <div>
//         <Footer />
//         {/* Other components can go here */}
//       </div>

//     </div>
//   );
// };

// export default BookingForm;
import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Styles/BookingForm.css';
import ExclusiveDeals from './ExclusiveDeals';
import TopDestination from './TopDestination';
import Banner from './Banner';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

const BookingForm = () => {
  const [tripType, setTripType] = useState('oneWay');
  const [fromCity, setFromCity] = useState('');
  const [toCity, setToCity] = useState('');
  const [returnDate, setReturnDate] = useState('');
  const [adults, setAdults] = useState(1);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [departureDate, setDepartureDate] = useState('');

  const [filteredFromCities, setFilteredFromCities] = useState([]);
  const [filteredToCities, setFilteredToCities] = useState([]);

  const citiesFrom = ["New York", "Los Angeles", "Chicago"];
  const citiesTo = ["London", "Tokyo", "Paris"];
  const navigate = useNavigate(); 
  const handleTripTypeChange = (type) => {
    setTripType(type);
  };

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   console.log('Search initiated');
  // };
  const handleSearch = (e) => {
    e.preventDefault();
    console.log('Search initiated');
    // Navigate to the flight list page
    navigate('/flights', {
      state: { fromCity, toCity, departureDate, returnDate, adults, children, infants } // Pass state if needed
    });
  };

  const handleFromChange = (e) => {
    const value = e.target.value;
    setFromCity(value);
    setFilteredFromCities(citiesFrom.filter(city => city.toLowerCase().includes(value.toLowerCase())));
  };

  const handleToChange = (e) => {
    const value = e.target.value;
    setToCity(value);
    setFilteredToCities(citiesTo.filter(city => city.toLowerCase().includes(value.toLowerCase())));
  };
  const destinations = [
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580576/honeymoon-in-maldives-1-700x466_wedyd2.jpg',
          name: 'Maldives',
          description: 'Experience the beauty of pristine beaches and clear waters.',
          price: 1500,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580637/best-places-to-live-in-paris-1_g2k4fs.jpg',
          name: 'Paris',
          description: 'Explore the romantic city and its stunning architecture.',
          price: 1800,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580720/1eee_rcfgqp.jpg',
          name: 'New Zealand',
          description: 'Discover the adventure capital of the world.',
          price: 3000,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580796/chureito-pagoda-and-mt-fuji-5c2fd0d546e0fb00015d4ed0_jmvtzu.jpg',
          name: 'Japan',
          description: 'Immerse yourself in rich culture and modern wonders.',
          price: 2200,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729580883/1-1ee_dnyhyr.jpg',
          name: 'Dubai',
          description: 'Immerse yourself in rich culture and modern wonders.',
          price: 3200,
        },
        
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581874/rajasthan_viultt.jpg',
          name: 'Rajasthan',
          description: 'Immerse yourself in rich culture and modern wonders.',
          price: 2100,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581742/kerla_pdjlnw.jpg',
          name: 'Kerla',
          description: 'Immerse yourself in rich culture and modern wonders.',
          price: 2700,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581803/goa_alcfft.jpg',
          name: 'Goa',
          description: 'Experience the beauty of pristine beaches and clear waters.',
          price: 1900,
        },
      
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729581976/kashmir_gj56lt.jpg',
          name: 'Kashmir',
          description: 'Explore the romantic city and its stunning architecture.',
          price: 5100,
        },
        {
          image: 'https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582170/himachalPredesh_ndihnk.jpg',
          name: 'Himachal Pradesh',
          description: 'Discover the adventure capital of the world.',
          price: 4000,
        },
      ];
     
  return (
    <div className='no-gap'>
      <div className='app-container no-gap'>
        <div className="container booking-form">
          <h3 className="text-center mb-3">Search Flight</h3>
          <form onSubmit={handleSearch}>
            {/* Trip type buttons */}
            <div className="form-group text-center">
              <div className="btn-group ">
                <button type="button" className={`btn btn-color ${tripType === 'oneWay' ? 'active' : ''}`} onClick={() => handleTripTypeChange('oneWay')}>One Way</button>
                <button type="button" className={`btn btn-color ${tripType === 'roundTrip' ? 'active' : ''}`} onClick={() => handleTripTypeChange('roundTrip')}>Round Trip</button>
                <button type="button" className={`btn btn-color ${tripType === 'multiCity' ? 'active' : ''}`} onClick={() => handleTripTypeChange('multiCity')}>Multi-City</button>
              </div>
            </div>

            {/* Form inputs */}
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="fromCity">From</label>
                <input
                  type="text"
                  className="form-control"
                  id="fromCity"
                  value={fromCity}
                  onChange={handleFromChange}
                  placeholder="Select City/Airport"
                />
                {fromCity && (
                  <ul className="suggestions">
                    {filteredFromCities.map((city) => (
                      <li key={city} onClick={() => { setFromCity(city); setFilteredFromCities([]); }}>
                        {city}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="toCity">To</label>
                <input
                  type="text"
                  className="form-control"
                  id="toCity"
                  value={toCity}
                  onChange={handleToChange}
                  placeholder="Select City/Airport"
                />
                {toCity && (
                  <ul className="suggestions">
                    {filteredToCities.map((city) => (
                      <li key={city} onClick={() => { setToCity(city); setFilteredToCities([]); }}>
                        {city}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="form-group col-md-4">
                <label htmlFor="departureDate">Departure Date</label>
                <input type="date" className="form-control" id="departureDate" value={departureDate} onChange={(e) => setDepartureDate(e.target.value)} />
              </div>
            </div>

            {tripType !== 'oneWay' && (
              <div className="form-group">
                <label htmlFor="returnDate">Return Date</label>
                <input type="date" className="form-control" id="returnDate" value={returnDate} onChange={(e) => setReturnDate(e.target.value)} />
              </div>
            )}

            <div className="form-row no-gap">
              <div className="form-group col-md-4">
                <label htmlFor="adults">Adults</label>
                <input type="number" className="form-control" id="adults" value={adults} onChange={(e) => setAdults(e.target.value)} min="1" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="children">Children</label>
                <input type="number" className="form-control" id="children" value={children} onChange={(e) => setChildren(e.target.value)} min="0" />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="infants">Infants</label>
                <input type="number" className="form-control" id="infants" value={infants} onChange={(e) => setInfants(e.target.value)} min="0" />
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-color">Search Flights</button>
            </div>
          </form>
        </div>
      </div>

      <div>
        <ExclusiveDeals />
      </div>

      <div>
        <TopDestination destinations={destinations} />
      </div>
      <div>
        <Banner />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BookingForm;
