import React from 'react';
import '../../Styles/MainFlight/DatePickers.css';
const DatePicker = () => {
  // Mock data for the dates and prices
  const dates = [
    { day: 'Wed, 09 Oct', price: '₹ 3628' },
    { day: 'Thu, 10 Oct', price: '₹ 3628' },
    { day: 'Fri, 11 Oct', price: '₹ 3628' },
    { day: 'Sat, 12 Oct', price: '₹ 3544', isDiscounted: true },
    { day: 'Sun, 13 Oct', price: '₹ 3544', isDiscounted: true },
    { day: 'Mon, 14 Oct', price: '₹ 3544', isDiscounted: true },
    { day: 'Tue, 15 Oct', price: '₹ 3795' },
    { day: 'Wed, 16 Oct', price: '₹ 3759' },
    { day: 'Thu, 17 Oct', price: '₹ 3795' },
    
  ];

  return (
    <div className="date-picker-container">
      <button className="scroll-btn left">{"<"}</button>
      <div className="dates-list">
        {dates.map((date, index) => (
          <div key={index} className="date-item">
            <span className="date-text">{date.day}</span>
            <span className={`price ${date.isDiscounted ? 'discount' : ''}`}>{date.price}</span>
          </div>
        ))}
      </div>
      <button className="scroll-btn right">{">"}</button>
    </div>
  );
};

export default DatePicker;
