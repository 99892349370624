import React from "react";
import { FaPlane } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function FlightList({ flights }) {
  const navigate = useNavigate();

  const handleBookNow = () => {
    // Navigate to the flight booking page
    navigate('/flight-booking');
  };
  return (
    <div className="flight-list">
      {flights.map(flight => (
        <div key={flight.id} className="flight-card">
         <FaPlane className="flight-icon" />
          <div className="flight-header">
            <div className="airline">{flight.airline}</div>
            <div className="flight-number">{flight.flightNumber}</div>
          </div>
          <div className="flight-timing">
            <div className="timing-container">
              <span className="departure">{flight.departure}</span>
             <span className="duration-container">{flight.duration}</span> 
                        
              <span className="arrival">{flight.arrival}</span>
            </div>
          </div>
          <div className="flight-price">
            <span className="price">{flight.price}</span>
            <span className="discount">{flight.discount}</span>
          </div>
          <button className="book-now-btn" onClick={handleBookNow}>
        Book Now
      </button>
        </div>
      ))}
    </div>
  );
}

export default FlightList;
