
// import React from 'react';
// import '../Styles/ExclusiveDeals.css';

// const ExclusiveDeals = () => {
//   return (
//     <div className="deals-container ">
//       <div className="deals-header ">
//         <h2>Exclusive Deals</h2>
//         {/*<a href="#" className="view-all">View All Deals</a>*/}
//       </div>
      
//       <div className="deals-list">
//         {/* First Deal */}
//         <div className="deal-item hot-deal">
//           <span className="hot-deal-label">HOT DEAL</span>
//           <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582919/slider2.jpg_pleuip.jpg" alt="Flight Deals" className="deal-image" />
//           <div className="deal-content">
//             <h3>Fly International </h3>
//             <p>Book Air France-KLM Airline Flight Tickets</p>
//             <a href="#" className="book-now">Book Now</a>
//           </div>
//         </div>

//         {/* Second Deal */}
//         <div className="deal-item">
//           <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582404/visa-free-countries_slkh6w.jpg" alt="Visa Services" className="deal-image" />
//           <div className="deal-content">
//             <h3>Apply Visa Online</h3>
//             <p>Avail discounts up to Rs.1000* on your Visa Services</p>
//             <a href="#" className="book-now">Book Now</a>
//           </div>
//         </div>

//         {/* Third Deal */}
//         <div className="deal-item">
//           <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582502/Europe1_spa38h.jpg" alt="Europe Tour" className="deal-image" />
//           <div className="deal-content">
//             <h3>European Countries 1 Tour</h3>
//             <p>Starting from 1,89,999</p>
//             <a href="#" className="book-now">Book Now</a>
//           </div>
//         </div>
//           {/* Four Deal */}
//           <div className="deal-item ">
//           <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582590/dubai1_jnrx0y.jpg" alt="Europe Tour" className="deal-image" />
//           <div className="deal-content no-gap">
//             <h3>Dubai Tour</h3>
//             <p>Starting from 1,89,999</p>
//             <a href="#" className="book-now">Book Now</a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExclusiveDeals;
import React from 'react';
import '../Styles/ExclusiveDeals.css';
import { useNavigate } from 'react-router-dom';

const ExclusiveDeals = () => {
  const navigate = useNavigate();

  const handleBookNowClick = () => {
    // Navigate to the flight offer page
    navigate('/deals');
  };

  const handleVisaClick = () => {
    // Navigate to the flight offer page
    navigate('/visa');
  };

  const handleHotelClick = () => {
    // Navigate to the flight offer page
    navigate('/destination/:destinationName');
  };
  return (
    <div className="deals-container ">
      <div className="deals-header ">
        <h2>Exclusive Deals</h2>
        {/*<a href="#" className="view-all">View All Deals</a>*/}
      </div>
      
      <div className="deals-list">
        {/* First Deal */}
        <div className="deal-item hot-deal">
          <span className="hot-deal-label">HOT DEAL</span>
          <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582919/slider2.jpg_pleuip.jpg" alt="Flight Deals" className="deal-image" />
          <div className="deal-content">
            <h3>Fly International </h3>
            <p>Book Air France-KLM Airline Flight Tickets</p>
            <a className="book-now" onClick={handleBookNowClick}>Book Now</a>
          </div>
        </div>

        {/* Second Deal */}
        <div className="deal-item">
          <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582404/visa-free-countries_slkh6w.jpg" alt="Visa Services" className="deal-image" />
          <div className="deal-content">
            <h3>Apply Visa Online</h3>
            <p>Avail discounts up to Rs.1000* on your Visa Services</p>
            <a className="book-now" onClick={handleVisaClick}>Book Now</a>
          </div>
        </div>

        {/* Third Deal */}
        <div className="deal-item">
          <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582502/Europe1_spa38h.jpg" alt="Europe Tour" className="deal-image" />
          <div className="deal-content">
            <h3>European Countries 1 Tour</h3>
            <p>Starting from 1,89,999</p>
            <a className="book-now" onClick={handleHotelClick}>Book Now</a>
          </div>
        </div>
          {/* Four Deal */}
          <div className="deal-item ">
          <img src="https://res.cloudinary.com/dnjz7of0v/image/upload/v1729582590/dubai1_jnrx0y.jpg" alt="Europe Tour" className="deal-image" />
          <div className="deal-content no-gap">
            <h3>Dubai Tour</h3>
            <p>Starting from 1,89,999</p>
            <a className="book-now" onClick={handleHotelClick}>Book Now</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExclusiveDeals;