
// import React, { useState } from 'react';
// import '../Styles/OTBDocuments.css';

// const OTBDocuments = () => {
//     const [fullName, setFullName] = useState('');
//     const [mobileNumber, setMobileNumber] = useState('');
//     const [airlines, setAirlines] = useState('Select');
//     const [price, setPrice] = useState(0);
//     const [uploadedFiles, setUploadedFiles] = useState({
//         passportFirst: null,
//         passportLast: null,
//         toUaeTicket: null,
//         fromUaeTicket: null,
//         visaCopy: null,
//         panCard: null
//     });

//     // Mapping of airline prices
//     const airlinePrices = {
//         Emirates: 200,
//         Etihad: 150,
//         FlyDubai: 100,
//     };

//     const handleFileChange = (e, fileKey) => {
//         setUploadedFiles({
//             ...uploadedFiles,
//             [fileKey]: e.target.files[0]
//         });
//     };

//     const handleAirlineChange = (e) => {
//         const selectedAirline = e.target.value;
//         setAirlines(selectedAirline);

//         // Update price based on the selected airline
//         const airlinePrice = airlinePrices[selectedAirline] || 0; // default to 0 if airline not found
//         setPrice(airlinePrice);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();

//         // Check if all files are uploaded
//         const allFilesUploaded = Object.values(uploadedFiles).every(file => file !== null);
//         if (!allFilesUploaded) {
//             alert('Please upload all files.');
//             return;
//         }

//         const formData = new FormData();
//         // Append files to FormData
//         for (const [key, file] of Object.entries(uploadedFiles)) {
//             formData.append(key, file);
//         }
//         // Append other form data
//         formData.append('fullName', fullName);
//         formData.append('mobileNumber', mobileNumber);

//         try {
//             const response = await fetch('https://backendapi.mmstourism.com/api/oTB/addOTBDetails', {
//                 method: 'POST',
//                 body: formData,
//             });

//             // Handle response
//             if (response.ok) {
//                 const jsonResponse = await response.json(); // Ensure it's JSON
//                 alert('Ready to Boarding...');

//                 // Reset the form fields after successful submission
//                 setFullName('');
//                 setMobileNumber('');
//                 setAirlines('Select');
//                 setPrice(0);
//                 setUploadedFiles({
//                     passportFirst: null,
//                     passportLast: null,
//                     toUaeTicket: null,
//                     fromUaeTicket: null,
//                     visaCopy: null,
//                     panCard: null
//                 });
//             } else {
//                 const errorMessage = await response.text(); // Get the error message if it's not JSON
//                 alert(`Error: ${response.status} - ${errorMessage}`);
//             }
//         } catch (error) {
//             console.error('Error submitting form:', error);
//         }
//     };

//     return (
//         <div className="otb-doc">
//             <h1 className="header">OK To Board</h1>
//             <form onSubmit={handleSubmit}>
//                 <div className="form-grid">
//                     <div className="form-group">
//                         <label>Full Name</label>
//                         <input 
//                             type="text" 
//                             value={fullName} 
//                             onChange={(e) => setFullName(e.target.value)} 
//                             placeholder="Enter your full name"
//                         />
//                     </div>

//                     <div className="form-group">
//                         <label>Mobile Number</label>
//                         <input 
//                             type="text" 
//                             value={mobileNumber} 
//                             onChange={(e) => setMobileNumber(e.target.value)} 
//                             placeholder="Enter your mobile number"
//                         />
//                     </div>

//                     <div className="form-group">
//                         <label>Airlines</label>
//                         <select value={airlines} onChange={handleAirlineChange}>
//                             <option>Select</option>
//                             <option value="Emirates">Emirates</option>
//                             <option value="Etihad">Etihad</option>
//                             <option value="FlyDubai">FlyDubai</option>
//                         </select>
//                     </div>

//                     <div className="form-group">
//                         <label>Price</label>
//                         <input type="text" value={`₹${price}`} disabled />
//                     </div>

//                     <div className="form-group">
//                         <label>Passport First Page</label>
//                         <input type="file" onChange={(e) => handleFileChange(e, 'passportFirst')} />
//                     </div>

//                     <div className="form-group">
//                         <label>Passport Last Page</label>
//                         <input type="file" onChange={(e) => handleFileChange(e, 'passportLast')} />
//                     </div>

//                     <div className="form-group">
//                         <label>To UAE Ticket</label>
//                         <input type="file" onChange={(e) => handleFileChange(e, 'toUaeTicket')} />
//                     </div>

//                     <div className="form-group">
//                         <label>From UAE Ticket</label>
//                         <input type="file" onChange={(e) => handleFileChange(e, 'fromUaeTicket')} />
//                     </div>

//                     <div className="form-group">
//                         <label>Visa Copy</label>
//                         <input type="file" onChange={(e) => handleFileChange(e, 'visaCopy')} />
//                     </div>

//                     <div className="form-group">
//                         <label>PAN Card</label>
//                         <input type="file" onChange={(e) => handleFileChange(e, 'panCard')} />
//                     </div>
//                 </div>
//                 <div className='button-container'>
//                     <button type="submit" className="submit-btn">Submit</button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default OTBDocuments;
import React, { useState } from 'react';
import '../Styles/OTBDocuments.css';

const OTBDocuments = () => {
    const [fullName, setFullName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [airlines, setAirlines] = useState('Select');
    const [price, setPrice] = useState(0);
    const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission progress

    const [uploadedFiles, setUploadedFiles] = useState({
        passportFirst: null,
        passportLast: null,
        toUaeTicket: null,
        fromUaeTicket: null,
        visaCopy: null,
        panCard: null
    });

    const airlinePrices = {
        Emirates: 200,
        Etihad: 150,
        FlyDubai: 100,
    };

    const handleFileChange = (e, fileKey) => {
        setUploadedFiles({
            ...uploadedFiles,
            [fileKey]: e.target.files[0]
        });
    };

    const handleAirlineChange = (e) => {
        const selectedAirline = e.target.value;
        setAirlines(selectedAirline);
        setPrice(airlinePrices[selectedAirline] || 0);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const allFilesUploaded = Object.values(uploadedFiles).every(file => file !== null);
        if (!allFilesUploaded) {
            alert('Please upload all files.');
            return;
        }

        const formData = new FormData();
        for (const [key, file] of Object.entries(uploadedFiles)) {
            formData.append(key, file);
        }
        formData.append('fullName', fullName);
        formData.append('mobileNumber', mobileNumber);

        setIsSubmitting(true); // Show progress bar

        try {
            const response = await fetch('https://backendapi.mmstourism.com/api/oTB/addOTBDetails', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const jsonResponse = await response.json();
                alert('Ready to Boarding...');
                setFullName('');
                setMobileNumber('');
                setAirlines('Select');
                setPrice(0);
                setUploadedFiles({
                    passportFirst: "",
                    passportLast: "",
                    toUaeTicket: "",
                    fromUaeTicket: "",
                    visaCopy: "",
                    panCard: ""
                });
            } else {
                const errorMessage = await response.text();
                alert(`Error: ${response.status} - ${errorMessage}`);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsSubmitting(false); // Hide progress bar
        }
    };

    return (
        <div className="otb-doc">
            <h1 className="header">OK To Board</h1>
            {isSubmitting && ( // Render progress bar during submission
                <div className="progress-bar">
                    <div className="progress-bar-inner"></div>
                </div>
            )}
            <form onSubmit={handleSubmit}>
                <div className="form-grid">
                    <div className="form-group">
                        <label>Full Name</label>
                        <input 
                            type="text" 
                            value={fullName} 
                            onChange={(e) => setFullName(e.target.value)} 
                            placeholder="Enter your full name"
                        />
                    </div>
                    <div className="form-group">
                        <label>Mobile Number</label>
                        <input 
                            type="text" 
                            value={mobileNumber} 
                            onChange={(e) => setMobileNumber(e.target.value)} 
                            placeholder="Enter your mobile number"
                        />
                    </div>
                    <div className="form-group">
                        <label>Airlines</label>
                        <select value={airlines} onChange={handleAirlineChange}>
                            <option>Select</option>
                            <option value="Emirates">Emirates</option>
                            <option value="Etihad">Etihad</option>
                            <option value="FlyDubai">FlyDubai</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Price</label>
                        <input type="text" value={`₹${price}`} disabled />
                    </div>
                    <div className="form-group">
                        <label>Passport First Page</label>
                        <input type="file" onChange={(e) => handleFileChange(e, 'passportFirst')} />
                    </div>
                    <div className="form-group">
                        <label>Passport Last Page</label>
                        <input type="file" onChange={(e) => handleFileChange(e, 'passportLast')} />
                    </div>
                    <div className="form-group">
                        <label>To UAE Ticket</label>
                        <input type="file" onChange={(e) => handleFileChange(e, 'toUaeTicket')} />
                    </div>
                    <div className="form-group">
                        <label>From UAE Ticket</label>
                        <input type="file" onChange={(e) => handleFileChange(e, 'fromUaeTicket')} />
                    </div>
                    <div className="form-group">
                        <label>Visa Copy</label>
                        <input type="file" onChange={(e) => handleFileChange(e, 'visaCopy')} />
                    </div>
                    <div className="form-group">
                        <label>PAN Card</label>
                        <input type="file" onChange={(e) => handleFileChange(e, 'panCard')} />
                    </div>
                </div>
                <div className='button-container'>
                    <button type="submit" className="submit-btn" disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </div>
            </form>
            <div>
                <br/><br/>
            </div>
        </div>
    );
};

export default OTBDocuments;
