import React, { useState } from 'react';
import '../Styles/FlightBooking.css';
import FlightDetails from './FlightDetails';

const FlightBooking = () => {
  const [promoCode, setPromoCode] = useState('');
  const [insurance, setInsurance] = useState('refundable');
  const baseFare = 5574;
  const taxes = 921;
  const insuranceCost = 909;
  const totalAmount = baseFare + taxes + (insurance === 'refundable' ? insuranceCost : 0);

  const applyPromoCode = (e) => {
    e.preventDefault();
    alert(`Promo code "${promoCode}" applied!`);
  };

  return (
    <div className="flight-booking-container">
      {/* Flight Details */}
      <div className="section flight-details">
        <h2>Review your flight details</h2>
        <div>
          <h3>Mumbai → New Delhi</h3>
          <div>
            <h4>Air India Express (IX-1304)</h4>
            <p>Depart: 18:25, Wed, 23 Oct 24</p>
            <p>From: Chhatrapati Shivaji International Airport (Mumbai)</p>
            <p>Arrival: 19:35, Dabolim South Goa (GOI)</p>
          </div>
          <div>
            <h4>Air India Express (IX-315)</h4>
            <p>Depart: 07:35, Thu, 24 Oct 24</p>
            <p>From: Dabolim South Goa (GOI)</p>
            <p>Arrival: 10:15, Indira Gandhi International Airport (New Delhi)</p>
          </div>
        </div>
      
      </div>

      {/* Fare Summary */}
      <div className="section fare-summary">
        <h3>Fare Summary</h3>
        <p>Base Fare: ₹{baseFare}</p>
        <p>Taxes & Charges: ₹{taxes}</p>
        {insurance === 'refundable' && <p>Insurance: ₹{insuranceCost}</p>}
        <h3>Total Amount: ₹{totalAmount}</h3>
      </div>

      {/* Promo Code Section */}
      <div className="section promo-code-form">
        <h3>Apply Promo Code</h3>
        <form onSubmit={applyPromoCode}>
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter promo code"
          />
          <button type="submit">Apply</button>
        </form>
        <div className="promo-code-offers">
          <p>Available offers:</p>
          <ul>
            <li>ATAUDD - ₹200 off on AU Small Finance Bank Cards</li>
            <li>ATDBS - ₹200 off on DBS Bank Cards</li>
            <li>ATHDFC - ₹200 off on HDFC Bank Cards</li>
          </ul>
        </div>
      </div>

      {/* Insurance Options */}
      <div className="section insurance-options">
        <h3>Booking Insurance</h3>
        <div>
          <input
            type="radio"
            id="refundable"
            name="insurance"
            value="refundable"
            checked={insurance === 'refundable'}
            onChange={() => setInsurance('refundable')}
          />
          <label htmlFor="refundable">Refundable Booking (₹{insuranceCost})</label>
        </div>
        <div>
          <input
            type="radio"
            id="non-refundable"
            name="insurance"
            value="non-refundable"
            checked={insurance === 'non-refundable'}
            onChange={() => setInsurance('non-refundable')}
          />
          <label htmlFor="non-refundable">Non-Refundable Booking</label>
        </div>
        <div>
          <p>With refundable booking, you are covered for situations like:</p>
          <ul>
            <li>Sickness/Accident</li>
            <li>Public Transport Failure</li>
            <li>Private Vehicle Failure</li>
            <li>Home Emergency</li>
            <li>Theft of Documents</li>
          </ul>
        </div>
      </div>
      <div>
            <FlightDetails/>
        </div>
    </div>
  );
};

export default FlightBooking;
