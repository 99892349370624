import React from 'react';
import '../Styles/FlightOffer.css'; // Import your CSS styles
import { useNavigate } from 'react-router-dom';
import cheapFlightImage from '../assets/images/cheapflight.jpg';
import RightPanel from '../Components/RightPanel';


const FlightOffer = () => {
    const navigate = useNavigate();

    const handleBookClick = () => {
        // Navigate to the homepage or booking page
        navigate('/');
    };

    return (
        <div className="container mt-9">
            <div className="row">
                {/* Flight Offer Container */}
                <div className="col-md-9">
                    <div className="offer-container">
                        <img
                            src={cheapFlightImage}
                            alt="Flight Deals"
                            className="deal-image"
                        />
                        <h1>Book Air France-KLM Airline Flight Tickets at LOWEST PRICE</h1>
                        <p className="offer-validity">Booking validity: Until 31st Oct 2024</p>
                        
                        <table className="offer-table">
                            <thead>
                                <tr>
                                    <th>Sectors</th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Mumbai-Amsterdam</td>
                                    <td>Amsterdam-Cancun</td>
                                    <td>Cancun-Amsterdam</td>
                                    <td>Amsterdam-Mumbai</td>
                                </tr>
                                <tr>
                                    <td>Mumbai-Paris</td>
                                    <td>Paris-Mumbai</td>
                                    <td>Toronto-Amsterdam</td>
                                    <td>Amsterdam-Delhi</td>
                                </tr>
                                <tr>
                                    <td>Spain-Amsterdam</td>
                                    <td>Amsterdam-Spain</td>
                                    <td>Spain-Georgetown</td>
                                    <td>Georgetown-Spain</td>
                                </tr>
                                <tr>
                                    <td>Mumbai-Amsterdam</td>
                                    <td>Amsterdam-Spain</td>
                                    <td>Spain-Georgetown</td>
                                    <td>Georgetown-Spain</td>
                                </tr>
                                <tr>
                                    <td>Delhi-Amsterdam</td>
                                    <td>Amsterdam-Las Vegas</td>
                                    <td>Las Vegas-Amsterdam</td>
                                    <td>Amsterdam-Delhi</td>
                                </tr>
                                <tr>
                                    <td>Delhi-Amsterdam</td>
                                    <td>Amsterdam-Toronto</td>
                                    <td>Toronto-Amsterdam</td>
                                    <td>Amsterdam-Delhi</td>
                                </tr>
                                <tr>
                                    <td>Kuala Lumpur-Amsterdam</td>
                                    <td>Amsterdam-Sao Paulo</td>
                                    <td>London Heathrow-Amsterdam</td>
                                    <td>Amsterdam-Prague</td>
                                </tr>
                                <tr>
                                    <td>Las Vegas-New York</td>
                                    <td>New York-Amsterdam</td>
                                    <td>Amsterdam-Delhi</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                        <p className="more-details">And Many more... Hurry, Book Now</p>

                        <div className="terms-conditions">
                            <h3>Terms & Conditions:</h3>
                            <ul>
                                <li>Offer is directly valid only on <a href="https://www.mmstourism.com" target="_blank" rel="noopener noreferrer">www.mmstourism.com</a> and MMS Travels App.</li>
                                <li>This offer cannot be clubbed with any other offer.</li>
                                <li>Date change, Rebooking, and Refund charges will apply as per fare rules.</li>
                                <li>MMStravels.com reserves the right to change or modify the offer.</li>
                                <li>MMStravels.com will not be liable for any loss due to force majeure events.</li>
                                <li>Customers bound by the terms and conditions provided.</li>
                            </ul>
                        </div>

                        <button className="book-now-btn" onClick={handleBookClick}>Book Now</button>
                    </div>
                </div>

                {/* HomePage Container */}
                <div className="col-md-3">
                    <div className="home-page-container">
                      <RightPanel />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FlightOffer;