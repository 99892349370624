import React from "react";

function FilterSection() {
  return (
    <div className="filter-section">
      <h3>Filter Flights</h3>
      <div className="filter-option">
        <label>Stops:</label>
        <select>
          <option value="0">0 Stops</option>
          <option value="1">1 Stop</option>
          <option value="2">1+ Stops</option>
        </select>
      </div>
      <div className="filter-option">
        <label>Airlines:</label>
        <div>
          <input type="checkbox" id="airIndia" name="airIndia" />
          <label htmlFor="airIndia">Air India</label>
        </div>
        <div>
          <input type="checkbox" id="indigo" name="indigo" />
          <label htmlFor="indigo">IndiGo</label>
        </div>
        <div>
          <input type="checkbox" id="spiceJet" name="spiceJet" />
          <label htmlFor="spiceJet">SpiceJet</label>
        </div>
        {/* Add more airlines... */}
      </div>
      <div className="filter-option">
        <label>Departure Times:</label>
        <div>
          <input type="radio" id="morning" name="departure" />
          <label htmlFor="morning">05:00 - 12:00</label>
        </div>
        <div>
          <input type="radio" id="afternoon" name="departure" />
          <label htmlFor="afternoon">12:00 - 18:00</label>
        </div>
        <div>
          <input type="radio" id="evening" name="departure" />
          <label htmlFor="evening">18:00 - 23:00</label>
        </div>
        
        {/* Add more filters... */}
      </div>
      <div className="filter-option">
        <label>Arrival Times:</label>
        <div>
          <input type="radio" id="morning" name="departure" />
          <label htmlFor="morning">05:00 - 12:00</label>
        </div>
        <div>
          <input type="radio" id="afternoon" name="departure" />
          <label htmlFor="afternoon">12:00 - 18:00</label>
        </div>
        <div>
          <input type="radio" id="evening" name="departure" />
          <label htmlFor="evening">18:00 - 23:00</label>
        </div>
        
        {/* Add more filters... */}
      </div>
      <div className="filter-price">
        <label>Price</label>
        <input type="range" min="27767" max="104153" />
        <p>₹ 27767 - ₹ 104153</p>
      </div>
    </div>
  );
}

export default FilterSection;
