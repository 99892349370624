
// import React from 'react';
// import { Link } from 'react-router-dom';
// import '../Styles/NavBar.css'; // Import the CSS file for styling

// const SideNav = () => {
//   return (
//     <div className="navbar">
//       <Link to="/" className="nav-item title">
    
//       MMS Tourism
//       </Link>
//       <div className="nav-links">
//         <Link to="/flights" className="nav-item">
//           <i className="fas fa-plane"></i>
//           Flights
//         </Link>
//         <Link to="/hotels" className="nav-item">
//           <i className="fas fa-hotel"></i>
//           Hotels
//         </Link>
//         <Link to="/visa" className="nav-item">
//           <i className="fas fa-passport"></i>
//           Visa
//         </Link>
//         <Link to="/otb" className="nav-item">
//           <i className="fas fa-passport"></i>
//           OTB
//         </Link>
//       </div>
//     </div>
//   );
// };

// export default SideNav;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../Styles/NavBar.css'; // Import the CSS file for styling

const SideNav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false); // Function to close the menu
  };

  return (
    <div className="navbar">
      <Link to="/" className="nav-item title" onClick={closeMenu}>
        MMS Tourism
      </Link>

      {/* Hamburger Icon for mobile view */}
      <div className="hamburger" onClick={toggleMenu}>
        <i className={menuOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>

      {/* Mobile & Desktop Nav Links */}
      <div className={`nav-links ${menuOpen ? 'mobile show' : ''}`}>
        <Link to="/flights" className="nav-item mobile" onClick={closeMenu}>
          <i className="fas fa-plane"></i>
          Flights
        </Link>
        <Link to="/hotels" className="nav-item mobile" onClick={closeMenu}>
          <i className="fas fa-hotel"></i>
          Hotels
        </Link>
        <Link to="/visa" className="nav-item mobile" onClick={closeMenu}>
          <i className="fas fa-passport"></i>
          Visa
        </Link>
        <Link to="/otb" className="nav-item mobile" onClick={closeMenu}>
          <i className="fas fa-passport"></i>
          OTB
        </Link>
      </div>
    </div>
  );
};

export default SideNav;
