import React from 'react';
import '../Styles/TripList.css';

const trips = [
  {
    title: "Dubai Escape",
    duration: "4 Nights & 5 Days",
    price: "86,999",
    oldPrice: null,
    image: "https://diarconsult.com/wp-content/uploads/2015/12/39_Five-Star.jpg",
    description: "Fixed Departure Holidays",
  },
  {
    title: "Dazzling Dubai with Yas Island",
    duration: "5 Nights & 6 Days",
    price: "92,616",
    oldPrice: "102,987",
    image: "https://www.travelduniya.today/wp-content/uploads/2019/10/434-views-01-dukes-dubai-royal-hideaway_tcm21-151719-1030x560.jpg",
    description: "Customized Holidays",
  },
  {
    title: "Dubai Escape",
    duration: "4 Nights & 5 Days",
    price: "86,999",
    oldPrice: null,
    image: "https://www.travelandleisure.com/thmb/DWxh8DWSyo3s912C3SGf3c002x8=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/TAL-sky-terrace-suite-living-room-atlantis-the-royal-dubai-ATLANTISDUBAI0123-aff545b6a4a4421a9795ae9c3e87ded7.jpg",
    description: "Fixed Departure Holidays",
  },
  {
    title: "Dazzling Dubai with Yas Island",
    duration: "5 Nights & 6 Days",
    price: "92,616",
    oldPrice: "102,987",
    image: "https://toim.b-cdn.net/pictures/tourgallery/1320/magical-dubai-6210.jpg",
    description: "Customized Holidays",
  },
  {
    title: "Dubai Escape",
    duration: "4 Nights & 5 Days",
    price: "86,999",
    oldPrice: null,
    image: "https://diarconsult.com/wp-content/uploads/2015/12/39_Five-Star.jpg",
    description: "Fixed Departure Holidays",
  },
  {
    title: "Dazzling Dubai with Yas Island",
    duration: "5 Nights & 6 Days",
    price: "92,616",
    oldPrice: "102,987",
    image: "https://www.travelduniya.today/wp-content/uploads/2019/10/434-views-01-dukes-dubai-royal-hideaway_tcm21-151719-1030x560.jpg",
    description: "Customized Holidays",
  },
];

const TripList = () => {
  return (
    <div className="trip-list">
      {trips.map((trip, index) => (
        <div className="trip-card" key={index}>
          <img src={trip.image} alt={trip.title} />
          <div className="trip-info">
            <h3 style={{ fontSize: '25px' }}>{trip.title}</h3>
            <p>{trip.duration}</p>
            <p>{trip.description}</p>
           
        </div>
        <div className='price-container'>
  <h5>Price</h5>
  {trip.oldPrice && <p className="old-price">₹ {trip.oldPrice}</p>}
  <p className="price">₹ {trip.price}</p>
  <button className="details-btn">View Details</button>  
</div>

            
        </div>
      ))}
    </div>
  );
};

export default TripList;
