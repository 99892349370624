import React from 'react';
import '../Styles/Destination.css';
import TripSidebar from './TripSidebar';
import TripList from './TripList';


const Destination= () => {
  return (
    <div className="appD">
      <TripSidebar />
      <TripList/>
    </div>
  );
};

export default Destination;
