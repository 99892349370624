// src/Components/Banner.js
import React from 'react';
import '../Styles/Banner.css';

const Banner = () => {
  return (
    <div className="banner-container">
      <div className="banner-content">
        <h1 className="banner-title">Explore the World with Us!</h1>
        <p className="banner-description">
          Discover breathtaking destinations and unforgettable experiences.
        </p>
        <a href="/tours" className="banner-button">Book Your Adventure</a>
      </div>
    </div>
  );
};

export default Banner;
