
import React, { useState } from 'react';
import axios from 'axios';
import '../Styles/VisaDocuments.css';

const VisaDocuments = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [selectedVisaCountry, setSelectedVisaCountry] = useState("Select");
  const [selectedVisaType, setSelectedVisaType] = useState("Select");
  const [selectedNationality, setSelectedNationality] = useState("Select");
  const [selectedPrice, setSelectedPrice] = useState("Price"); // Default Price
  const [fullName, setFullName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  
  const [passportFirstPage, setPassportFirstPage] = useState(null);
  const [passportLastPage, setPassportLastPage] = useState(null);
  const [colorPhoto, setColorPhoto] = useState(null);
  const [panCard, setPanCard] = useState(null);
  
  
  const [uploadedImages, setUploadedImages] = useState([]);

  const fileTypes = ['image/jpeg', 'image/png'];

  // Mapping visa types to their respective prices
  const visaPrices = {
    '30 Days Dubai Visa': '₹ 2000',
    '60 Days Dubai Visa': '₹ 4000',
  };

  const handleFileChange = (event, setter) => {
    const file = event.target.files[0];
    if (file && fileTypes.includes(file.type)) {
      setter(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImages(prevImages => [...prevImages, reader.result]);
      };
      reader.readAsDataURL(file);
    } else {
      alert('Please upload a valid image (JPEG or PNG).');
    }
  };

  const handleVisaTypeChange = (event) => {
    const selectedType = event.target.value;
    setSelectedVisaType(selectedType);
    
    // Update price based on the selected visa type
    setSelectedPrice(visaPrices[selectedType] || 'Price');
  };

 
  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!isAgreed || !passportFirstPage || !passportLastPage || !colorPhoto || !panCard) {
      alert("Please agree to the terms and upload all required files.");
      return;
    }
  
    // Prepare form data
    const formData = new FormData();
    formData.append('firstName', fullName);
    formData.append('mobileNumber', mobileNumber);
    formData.append('countryName', selectedVisaCountry);
    formData.append('passportType', selectedVisaType);
    formData.append('nationality', selectedNationality);
    formData.append('price', selectedPrice);
    formData.append('imageFirstPage', passportFirstPage);
    formData.append('imageLastPage', passportLastPage);
    formData.append('imagePhoto', colorPhoto);
    formData.append('imagePanCard', panCard);
  
    // Show pop-up immediately and clear form fields
    alert('Submitting your visa details...');
    
    // Clear form fields
    setFullName("");
    setMobileNumber("");
    setSelectedVisaCountry('Select');
    setSelectedVisaType('Select');
    setSelectedNationality('Select');
    setSelectedPrice('Price');
    setPassportFirstPage(null);
    setPassportLastPage(null);
    setColorPhoto(null);
    setPanCard(null);
    setIsAgreed(false);
    setUploadedImages([]);
  
    try {
      const response = await axios.post('https://backendapi.mmstourism.com/api/visa/addVisaDetails', formData);
      alert(response.data.message || 'Visa details added successfully!');
    } catch (error) {
      console.error(error);
      alert('An error occurred while submitting the form.');
    }
  };
  
  return (
    <div className="visa-documents">
      <img src="https://www.frenchmomentsblog.com/wp-content/uploads/2011/03/Tour-Eiffel-French-Moments-02.jpg" alt="Visa Image" className="visa-image" /> {/* Replace with your image URL */}
      <div className="form-container">
        <h1>Visa Details</h1>
        <form onSubmit={handleSubmit} className="visa-form">
          <div className="form-row">
            <input 
              type="text" 
              placeholder="First Name" 
              onChange={(e) => setFullName(e.target.value)} 
              required 
            />
            <input 
              type="text" 
              placeholder="Mobile Number" 
              onChange={(e) => setMobileNumber(e.target.value)} 
              required 
            />
          </div>

          <div className="form-row">
            <select 
              value={selectedVisaCountry} 
              onChange={(e) => setSelectedVisaCountry(e.target.value)} 
              required
            >
              <option value="Select">Select Visa Country</option>
              <option value="UAE">UAE</option>
            </select>

            <select 
              value={selectedVisaType} 
              onChange={handleVisaTypeChange} // Updated onChange handler
              required
            >
              <option value="Select">Select Visa Type</option>
              <option value="30 Days Dubai Visa">30 Days Dubai Visa</option>
              <option value="60 Days Dubai Visa">60 Days Dubai Visa</option>
            </select>
          </div>

          <div className="form-row">
            <select 
              value={selectedNationality} 
              onChange={(e) => setSelectedNationality(e.target.value)} 
              required
            >
              <option value="Select">Select Nationality</option>
              <option value="Indian">Indian</option>
            </select>
            <input 
              type="text" 
              value={selectedPrice} // Show the selected price
              readOnly
            />
          </div>

          <div className="file-upload">
            <div className='form-row'>
              <label>
                Passport First Page:
                <input type="file" onChange={(e) => handleFileChange(e, setPassportFirstPage)} required />
              </label>
              <label>
                Passport Last Page:
                <input type="file" onChange={(e) => handleFileChange(e, setPassportLastPage)} required />
              </label>
            </div>
            <div className='form-row'>
              <label>
                Upload Color Photo:
                <input type="file" onChange={(e) => handleFileChange(e, setColorPhoto)} required />
              </label>
              <label>
                Pan Card:
                <input type="file" onChange={(e) => handleFileChange(e, setPanCard)} required />
              </label>
            </div>
          </div>

          <div className="agreement">
            <input 
              type="checkbox" 
              checked={isAgreed} 
              onChange={() => setIsAgreed(!isAgreed)} 
              required 
            />
            <label>I agree to the Terms and Conditions</label>
          </div>

          <div className='button-container'>
            <button type="submit" className="submit-button">Submit</button>
          </div>

        
        </form>
        
      </div>
      {/*<div className='upload-file'>
      {uploadedImages.length > 0 && (
            <div className="uploaded-images">
              <h2>Uploaded Images</h2>
              {uploadedImages.map((image, index) => (
                <img key={index} src={image} alt={`Uploaded Document ${index + 1}`} className="uploaded-image" />
              ))}
            </div>
          )}
      </div>*/}
    </div>
  );
}

export default VisaDocuments;
